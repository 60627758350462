import { Switch, Route, Redirect } from 'react-router-dom';
import SigningFormPage from 'src/components/SigningForm/SigningFormPage';
import StudentPage from 'src/components/StudentPage/StudentPage';
import PrivacyPolicyPage from '../Main/PrivacyPolicy/PrivacyPolicyPage';
import FormPage from '../Student/StudentForms/FormPage/FormPage';
import SupervisorClass from './SupervisorClasses/SupervisorClass/SupervisorClass';
import SupervisorClasses from './SupervisorClasses/SupervisorClasses';

const SupervisorRouter = () => (
  <Switch>
    <Route exact path="/supervisor">
      <SupervisorClasses />
    </Route>
    <Route exact path="/supervisor/class/:classId">
      <SupervisorClass />
    </Route>
    <Route exact path="/supervisor/class/:classId/student/:userId">
      <StudentPage />
    </Route>
    <Route path="/supervisor/class/:classId/student/:studentUserId/form/:formId/assignment/:assignmentId">
      <FormPage />
    </Route>
    <Route exact path="/supervisor/form/:formId">
      <FormPage />
    </Route>
    <Route path="/supervisor/class/:classId/student/:studentUserId/signingform">
      <SigningFormPage />
    </Route>
    <Route path="/privacy-policy">
      <PrivacyPolicyPage />
    </Route>
    <Redirect from="*" to="/supervisor" />
  </Switch>
);
export default SupervisorRouter;
