import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Class } from 'src/models/classes';
import SigningForm from 'src/models/SigningForm';
import Card from '../_common/Card/Card';
import CardList from '../_common/CardList/CardList';
import { getMainFormsInClass, getNonMainFormsInClass } from './utils';

interface FormsSectionProps {
  class: Class,
  signingForm?: SigningForm;
}

export default function FormsSection({ class: _class, signingForm: _signingForm }: FormsSectionProps) {
  const { formatMessage } = useIntl();
  const history = useHistory();

  function navigateToForm(formId: number, assignmentId: number) {
    history.push({ pathname:
      `${history.location.pathname}/form/${formId}/assignment/${assignmentId}`,
    state: { pPath: history.location.pathname } });
  }

  const midSemesterDate = _class.midSemesterDate && new Date(_class.midSemesterDate).toLocaleDateString().replace(/\//g, '.');
  const endSemesterDate = _class.endSemesterDate && new Date(_class.endSemesterDate).toLocaleDateString().replace(/\//g, '.');

  return (
    <>
      <CardList header={formatMessage({ id: 'respons-grading' })} className="mt-8">
        {getMainFormsInClass(_class, _signingForm).map(form => (
          <Card
            key={form.id}
            onClick={() => navigateToForm(form.id, form.assignmentId)}
            header={form.name}
            body={formatMessage({ id: 'deadline' }, { date: form.isMidSemester ? midSemesterDate : endSemesterDate })}
          />
        ))}
      </CardList>
      <CardList header={formatMessage({ id: 'specific-competencies' })}>
        {getNonMainFormsInClass(_class).map(form => (
          <Card
            key={form.id}
            primary={false}
            onClick={() => navigateToForm(form.id, form.assignmentId)}
            header={form.name}
            body={formatMessage({ id: 'deadline' }, { date: endSemesterDate })}
          />
        ))}
      </CardList>
    </>
  );
}
