import { Class } from 'src/models/classes';
import uniqBy from 'src/utils/array';
import SigningForm from 'src/models/SigningForm';

export function getMainFormsInClass(_class: Class, signingForm?: SigningForm) {
  return getUniqueClassForms(_class, signingForm).filter(_form => _form.isMainForm);
}

export function getNonMainFormsInClass(_class: Class) {
  return getUniqueClassForms(_class).filter(form => !form.isMainForm);
}

function midSemesterHasPassed(signingForm?: SigningForm) {
  if (signingForm && signingForm.midSemesterCompletedDate) {
    const midSemesterDate = new Date(signingForm.midSemesterCompletedDate);
    const today = new Date();
    return today > midSemesterDate;
  }
  return false;
}

function getUniqueClassForms(_class: Class, signingForm?: SigningForm) {
  const relevantAssignments = midSemesterHasPassed(signingForm)
    ? _class.assignments?.filter(assignment => !assignment.isMidSemester) : _class.assignments;
  const classForms = relevantAssignments?.map(assignment => assignment.forms.map(_form => ({ ..._form, assignmentId: assignment.id, isMidSemester: assignment.isMidSemester }))).flat() ?? [];
  return uniqBy(classForms, 'id') as {
    assignmentId: number;
    isMidSemester: boolean;
    id: number;
    isMainForm: boolean;
    name: string;
}[];
}
